// import bw from "../assets/adaBw.png";

const Upcoming = [
  // {
  //   id: 1,
  //   title: "Topic:",
  //   description: "The importance of educating the girl child",
  //   date: " 28TH of march 2023",
  //   venue: "Physical speaking event at the unilag Hall week, Unilag, Faguwa Hall.",
  // },
];

export default Upcoming;
